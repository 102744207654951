
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('seedsSeeds.dealer_wise_disburse') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Organization" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro.organization')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="search.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      :label="$t('globalTrans.division')"
                      label-for="division_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.division_id"
                          :options="divisionList"
                          id="division_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                      class="row"
                      label-cols-sm="3"
                      :label="$t('org_pro_district.district')"
                      label-for="district_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.district_id"
                          :options="districtList"
                          id="district_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                      </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_upazilla.upazilla')"
                    label-for="upazilla_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.upazilla_id"
                        :options="upazilaList"
                        id="upazilla_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('seedsSeeds.office')"
                    label-for="to_sales_office_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.to_sales_office_id"
                        :options="officeList"
                        id="to_sales_office_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('seedsSeeds.dealer_wise_disburse_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                            {{ $t('globalTrans.add_new') }}
                        </b-button> -->
                        <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered striped hover  :emptyText="$t('globalTrans.noDataFound')" show-empty :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(total_land)="data">
                                            {{ $n(data.item.total_land) }}
                                        </template>
                                        <template v-slot:cell(allocation_type)="data">
                                            {{ getAllocationType (data.item.allocation_type) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button class="mr-1" title="View Complain" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button> -->
                                          <a href="javascript:" v-b-modal.modal-5 @click="details(data.item)" class="btn_table_action table_action_view" title="View Complain">
                                            <i class="fas fa-eye"></i>
                                          </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="lg" :title="$t('seedsSeeds.dealer_wise_disburse_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="detailsItemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { dealerDisburseList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        org_id: this.$store.state.dataFilters.orgId,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        to_sales_office_id: this.$store.state.dataFilters.officeId
      },
      rows: [],
      item: '',
      detailsItemId: '',
      districtList: [],
      upazilaList: [],
      officeList: []
    }
  },
  mounted () {
  },
  computed: {
    orgList: function () {
    const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        return tmpData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('seedsSeeds.dealer_wise_disburse') + ' ' + this.$t('globalTrans.entry') : this.$t('seedsSeeds.dealer_wise_disburse') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('org_pro.organization'), class: 'text-center' },
          { label: this.$t('globalTrans.division'), class: 'text-center' },
          { label: this.$t('globalTrans.district'), class: 'text-center' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
          { label: this.$t('seedsSeeds.office'), class: 'text-center' },
          { label: this.$t('seedsSeeds.dealer_name'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'office_name_bn' },
          { key: 'dealer_name_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'division_name' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'office_name' },
          { key: 'dealer_name' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
  'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
      this.upazilaList = []
    },
    'search.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
        this.officeList = this.getOfficeList(newVal)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
      this.detailsItemId = item.id
    },
    // --------------------Data Set For Table Start----------------------
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const response = await RestApi.getData(seedFertilizerServiceBaseUrl, dealerDisburseList, params)
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelatinalData (data) {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList
        const productSeasonList = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList
        const unitList = this.$store.state.SeedsFertilizer.commonObj.unitList
        const orgList = this.$store.state.commonObj.organizationProfileList
        const divisionList = this.$store.state.commonObj.divisionList
        const districtList = this.$store.state.commonObj.districtList
        const upazilaList = this.$store.state.commonObj.upazilaList
        const officeList = this.$store.state.commonObj.officeList
        const dealerList = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList
        const listData = data.map(item => {
          const fiscalYearObj = fiscalYearList.find(year => year.value === item.fiscal_year_id)
          const SesonObj = productSeasonList.find(season => season.value === item.production_season_id)
          const unitObj = unitList.find(unit => unit.value === item.measurement_unit_id)
          const orgObject = orgList.find(org => org.value === item.org_id)
          const divisionObject = divisionList.find(division => division.value === item.division_id)
          const districtObject = districtList.find(district => district.value === item.district_id)
          const upazillaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
          const officeObject = officeList.find(office => office.value === item.to_sales_office_id)
          const dealerObj = dealerList.find(dealer => dealer.value === item.off_dealer_farmer_comp_id)
          const fiscalYearData = {
            fiscal_year: fiscalYearObj !== undefined ? fiscalYearObj.text_en : '',
            fiscal_year_bn: fiscalYearObj !== undefined ? fiscalYearObj.text_bn : ''
          }
          const productionSesonData = {
            season_name: SesonObj !== undefined ? SesonObj.text_en : '',
            season_name_bn: SesonObj !== undefined ? SesonObj.text_bn : ''
          }
          const unitData = {
            unit_name: unitObj !== undefined ? unitObj.text_en : '',
            unit_name_bn: unitObj !== undefined ? unitObj.text_bn : ''
          }
          const divisionData = {
            division_name: divisionObject !== undefined ? divisionObject.text_en : '',
            division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
          }
          const districtData = {
            district_name: districtObject !== undefined ? districtObject.text_en : '',
            district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
          }
          const upazillaData = {
            upazilla_name: upazillaObject !== undefined ? upazillaObject.text_en : '',
            upazilla_name_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ''
          }
           const orgData = {
            org_name: orgObject !== undefined ? orgObject.text_en : '',
            org_name_bn: orgObject !== undefined ? orgObject.text_bn : ''
          }
          const officeData = {
            office_name: officeObject !== undefined ? officeObject.text_en : '',
            office_name_bn: officeObject !== undefined ? officeObject.text_bn : ''
          }
          const dealerData = {
            dealer_name: dealerObj !== undefined ? dealerObj.text_en : '',
            dealer_name_bn: dealerObj !== undefined ? dealerObj.text_bn : ''
          }
        return Object.assign({}, item, fiscalYearData, productionSesonData, unitData, divisionData, districtData, upazillaData, orgData, officeData, dealerData)
      })
      return listData
    },
    getDistrictList (DivisionId) {
      const list = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && item.division_id === DivisionId)
        return list.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
          return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getOfficeList (DistrictId) {
        const officeList = this.$store.state.SeedsFertilizer.commonObj.zoneOfficeList.filter(item => item.status === 1 && item.district_id === DistrictId)
        return officeList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getAllocationType (type) {
      if (type === 1) {
        return this.$i18n.locale === 'bn' ? 'ইনস্টিটিউট' : 'Institute'
      } else if (type === 2) {
        return this.$i18n.locale === 'bn' ? 'কৃষক' : 'Farmer'
      }
    }
    }
}
</script>
