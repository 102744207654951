<template>
    <b-container fluid>
        <b-row>
            <b-overlay :show="saveLoader">
                <b-col lg="12" sm="12">
                    <iq-card>
                        <template v-slot:body>
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                    <b-row xs="12" sm="12" md="12" lg="12" xl="12">
                                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id"  rules="required|min_value:1">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="fiscal_year_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.fiscal_year_id"
                                                    :options="fiscalList"
                                                    id="fiscal_year_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                            <ValidationProvider name="Season" vid="production_season_id"  rules="required|min_value:1">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="production_season_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('seedsConfig.productionSeason')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.production_season_id"
                                                    :options="productionSeasonList"
                                                    id="production_season_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                            <ValidationProvider name="Measurement Unit ID" vid="measurement_unit_id"  rules="required|min_value:1">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="measurement_unit_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('seedsConfig.measurementUnit')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.measurement_unit_id"
                                                    :options="measurementUnitList"
                                                    id="measurement_unit_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row xs="12" sm="12" md="12" lg="12" xl="12">
                                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="org_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.org_id"
                                                    :options="orgList"
                                                    id="org_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                            <ValidationProvider name="zone Office Type" vid="zone_office_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="zone_office_type_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{$t('seedsSeeds.office_type')}}<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="formData.zone_office_type_id"
                                                    :options="zoneOfficeTypeList"
                                                    id="zone_office_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                            <ValidationProvider name="office" vid="office_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="office_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('seedsSeeds.office_name')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.office_id"
                                                    :options="officeList"
                                                    id="office_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row xs="12" sm="12" md="12" lg="12" xl="12">
                                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                            <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="division_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.division_id"
                                                    :options="divisionList"
                                                    id="division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                            <ValidationProvider name="district" vid="district_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="district_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.district_id"
                                                    :options="districtList"
                                                    id="district_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                            <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="upazilla_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.upazilla_id"
                                                    :options="upazillaList"
                                                    id="upazilla_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                            <ValidationProvider name="Dealer Name" vid="off_dealer_farmer_comp_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="off_dealer_farmer_comp_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{ $t('seedsSeeds.dealer_name')}}<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="formData.off_dealer_farmer_comp_id"
                                                    id="off_dealer_farmer_comp_id"
                                                    :options="dealerList"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disable>{{ $t('seedsSeeds.all_dealer') }}-{{ $n(dealerNo) }}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <template>
                                        <div style="font-size:15px; background-color: #005B5B;">
                                        <h5 class="text-white text-center mb-3">
                                            {{ $t('seedsSeeds.dealer_wise_disburse_multiple') }}
                                        </h5>
                                        </div>
                                    </template>
                                    <div style="border:1px solid #4D4442; margin-bottom:20px; padding:10px; margin-top:10px">
                                        <b-overlay :show="loading">
                                            <div class="row">
                                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <ValidationProvider name="Seed Class" vid="seed_class">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="seed_class"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('seedsConfig.seedClass')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="details.seed_class_id"
                                                            :options="seedClassList"
                                                            id="seed_class_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <ValidationProvider name="Crop Type" vid="crop_type">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="crop_type"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('seedsConfig.cropType')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="details.crop_type_id"
                                                            :options="cropTypeList"
                                                            id="crop_type_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <ValidationProvider name="Crop Name" vid="crop_name_id">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="crop_name_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('seedsConfig.cropName')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="details.crop_name_id"
                                                            :options="cropNameList"
                                                            id="crop_name_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <ValidationProvider name="variety" vid="variety_id">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="variety_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('seedsSeeds.varietyName')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="details.variety_id"
                                                            :options="varietyList"
                                                            id="variety_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <ValidationProvider name="Allocation Quantity" vid="allocation_qnty">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="allocation_qnty"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('seedsSeeds.allocation_qnty')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            readonly
                                                            type="number"
                                                            id="allocation_qnty"
                                                            v-model="details.allocation_qnty"
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <ValidationProvider name="Receive Quantity" vid="receive_qnty">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="receive_qnty"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('seedsSeeds.before_receive_amount')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            readonly
                                                            type="number"
                                                            id="receive_qnty"
                                                            v-model="details.receive_qnty"
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <ValidationProvider name="Allocation Quantity" vid="avaliable_qnty">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="avaliable_qnty"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('seedsSeeds.avaliable_qnty')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            readonly
                                                            type="number"
                                                            id="avaliable_qnty"
                                                            v-model="details.avaliable_qnty"
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <ValidationProvider name="Sales Quantity" vid="sales_qnty" rules="max_value:@avaliable_qnty">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="sales_qnty"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('seedsSeeds.sales_qnty')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            type="number"
                                                            id="sales_qnty"
                                                            v-model="details.sales_qnty"
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <div class="text-right">
                                                        <button @click="addItem" class="btn btn-sm btn-primary mr-2" type="button"
                                                    ><i class="fas fa-plus-circle m-0"></i> {{$t('dae_subsidy.add')}}</button>
                                                    </div>
                                            </b-col>
                                            </div>
                                        </b-overlay>
                                    </div>
                                    <!-- -----------------Add More Start------------------- -->
                                    <b-table-simple striped bordered small class="mt-3">
                                            <b-thead>
                                            <tr>
                                                <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                                <b-th>{{ $t('seedsSeeds.varietyName') }}</b-th>
                                                <b-th>{{$t('seedsSeeds.allocation_amount')}}</b-th>
                                                <b-th>{{$t('seedsSeeds.before_receive_amount')}}</b-th>
                                                <b-th>{{$t('seedsSeeds.remaining_amount')}}</b-th>
                                                <b-th>{{$t('seedsSeeds.present_sales_qnty')}}</b-th>
                                                <b-th>{{$t('seedsSeeds.present_remaining')}}</b-th>
                                                <b-th>{{$t('seedsSeeds.unitPrice')}}</b-th>
                                                <b-th>{{$t('seedsSeeds.totalPrice')}}</b-th>
                                                <b-th>{{ $t('globalTrans.action') }}</b-th>
                                            </tr>
                                            </b-thead>
                                            <b-tbody>
                                            <template v-if="formData.farComdetails && formData.farComdetails.length">
                                                <b-tr v-for="(item, index) in formData.farComdetails" :key="index">
                                                <b-td  class="text-center">{{ index+1 }}</b-td>
                                                <b-td class="text-center">{{ currentLocale === 'en' ? item.variety_name : item.variety_name }}</b-td>
                                                <b-td class="text-center">{{ $n(item.allocation_qnty) }}</b-td>
                                                <b-td class="text-center">{{ $n(item.receive_qnty) }}</b-td>
                                                <b-td class="text-center">{{ $n(item.remaining_qnty) }}</b-td>
                                                <b-td>
                                                    <b-form-input
                                                    v-model="item.sales_qnty"
                                                    type="number"
                                                    min=1
                                                    ></b-form-input>
                                                </b-td>
                                                <b-td class="text-center">{{ $n(item.present_remaining) }}</b-td>
                                                <b-td class="text-center">{{ $n(item.unit_price) }}</b-td>
                                                <b-td class="text-center">{{ $n(item.total_price) }}</b-td>
                                                <b-td class="text-center">
                                                    <button @click="remove(index)" class="btn btn-sm btn-danger" type="button"><i class="fas fa-window-close m-0"></i></button>
                                                </b-td>
                                                </b-tr>
                                            </template>
                                            <template v-else>
                                                <b-tr>
                                                <b-td colspan="10" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                </b-tr>
                                            </template>
                                            </b-tbody>
                                            <b-tfoot>
                                            </b-tfoot>
                                        </b-table-simple>
                                    <!-- -----------------Add More End--------------------- -->
                                    <div class="row">
                                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-col>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </template>
                    </iq-card>
                </b-col>
            </b-overlay>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { dealerDisburseStore, delarWiseUnitPrice, dealerDisburseQty } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        this.formData.org_id = this.$store.state.dataFilters.orgId
         this.formData = Object.assign({}, this.formData, { fiscal_year_id: this.currentFiscalYearId })
        if (this.id) {
            const tmp = this.getformDataData()
            this.formData = tmp
        }
    },
    data () {
        return {
            loading: false,
            applicantLoading: false,
            showOtherField: false,
            instrumentSel: false,
            cropsSel: false,
            meteriatSel: true,
            saveLoader: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            formData: {
                fiscal_year_id: 0,
                production_season_id: 0,
                measurement_unit_id: 2,
                org_id: 0,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                zone_office_type_id: 0,
                office_id: this.$store.state.dataFilters.officeId,
                allocation_type: 0,
                off_dealer_farmer_comp_id: 0,
                other_designation: '',
                farComdetails: []
            },
            details: {
                seed_class_id: 0,
                crop_type_id: 0,
                crop_name_id: 0,
                variety_id: 0,
                avaliable_qnty: 0,
                sales_qnty: '',
                allocation_qnty: 0,
                receive_qnty: 0
            },
            districtList: [],
            upazillaList: [],
            applicantList: [],
            cropNameList: [],
            officeList: [],
            varietyList: [],
            dealerList: [],
            zoneOfficeTypeList: []
        }
    },
    watch: {
        'formData.division_id': function (newVal, oldVal) {
            if (newVal) {
                this.districtList = this.getDistrictList(newVal)
            } else {
               this.districtList = []
            }
        },
        'formData.district_id': function (newVal, oldVal) {
            if (newVal) {
                this.upazillaList = this.getUpazilaList(newVal)
            } else {
                this.upazillaList = []
                this.officeList = []
            }
        },
        'formData.upazilla_id': function (newVal, oldVal) {
            if (newVal) {
                this.dealerList = this.getDealerList(newVal)
            } else {
                this.dealerList = []
            }
        },
        'formData.allocation_type': function (newVal, oldVal) {
            if (newVal === 1 || newVal === 2) {
                this.applicantList = this.getApplicantList(newVal)
            }
        },
        'details.crop_type_id': function (newVal, oldVal) {
            if (newVal) {
                this.cropNameList = this.getCropNameList(newVal)
            } else {
                this.cropNameList = []
            }
        },
        'details.crop_name_id': function (newVal, oldVal) {
            if (newVal) {
                this.varietyList = this.getVarietyList(newVal)
            } else {
                this.varietyList = []
            }
        },
        'details.variety_id': function (newVal, oldVal) {
            if (newVal) {
                this.getVarietyAvaialableQuantity()
            }
        },
        'formData.org_id': function (newVal, oldVal) {
            this.zoneOfficeTypeList = this.getZoneOfficeTypeList(newVal)
            this.formData.zone_office_type_id = this.$store.state.dataFilters.officeTypeId
        },
        'formData.zone_office_type_id': function (newVal, oldVal) {
            this.officeList = this.getZoneOfficeList(newVal)
        }

    },
    mounted () {
        core.index()
    },
    computed: {
        orgList: function () {
            const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
            return tmpData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        fiscalList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        productionSeasonList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
        },
        seedClassList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.status === 1)
        },
        measurementUnitList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.unitList
        },
        cropTypeList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
        },
        currentLocale: function () {
            return this.$i18n.locale
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        currentFiscalYearId: function () {
            return this.$store.state.SeedsFertilizer.currentFiscalYearId
        }
    },
    methods: {
        getformDataData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.saveLoader = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const loadingState = { loading: false, listReload: false }
            const result = await RestApi.postData(seedFertilizerServiceBaseUrl, dealerDisburseStore, this.formData)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$store.commit('mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
            this.saveLoader = false
        },
        async addItem () {
            if (this.details.seed_class_id && this.details.crop_type_id && this.details.crop_name_id && this.details.variety_id && this.details.avaliable_qnty && this.details.sales_qnty > 0) {
                const searchIteams = {
                    fiscal_year_id: this.formData.fiscal_year_id,
                    org_id: this.formData.org_id,
                    production_season_id: this.formData.production_season_id,
                    office_id: this.formData.office_id,
                    seed_class_id: this.details.seed_class_id,
                    crop_type_id: this.details.crop_type_id,
                    crop_name_id: this.details.crop_name_id,
                    measurement_unit_id: this.formData.measurement_unit_id,
                    variety_id: this.details.variety_id,
                    customer_id: 3
                }
                const result = await RestApi.getData(seedFertilizerServiceBaseUrl, delarWiseUnitPrice, searchIteams)
                if (result.success) {
                    const seedClass = this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.value === this.details.seed_class_id)
                    const cropType = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.value === this.details.crop_type_id)
                    const cropName = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.value === this.details.crop_name_id)
                    const varietyName = this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.value === this.details.variety_id)
                    const remainingQnty = parseFloat(this.details.allocation_qnty) - (this.details.receive_qnty ? parseFloat(this.details.receive_qnty) : 0)
                    const salesQnty = parseFloat(this.details.sales_qnty)
                    const obj = {
                        seed_class_id: this.details.seed_class_id,
                        seed_name: seedClass[0]?.text_en,
                        seed_name_bn: seedClass[0]?.text_bn,
                        crop_type_id: this.details.crop_type_id,
                        crop_type: cropType[0]?.text_en,
                        crop_type_bn: cropType[0]?.text_bn,
                        crop_name_id: this.details.crop_name_id,
                        crop_name: cropName[0]?.text,
                        crop_name_bn: cropName[0]?.text,
                        variety_id: this.details.variety_id,
                        variety_name: varietyName[0]?.text,
                        variety_name_bn: varietyName[0]?.text,
                        avaliable_qnty: parseFloat(this.details.avaliable_qnty),
                        allocation_qnty: parseFloat(this.details.allocation_qnty),
                        receive_qnty: parseFloat(this.details.receive_qnty),
                        remaining_qnty: remainingQnty,
                        sales_qnty: salesQnty,
                        present_remaining: remainingQnty - salesQnty,
                        unit_price: result.data,
                        total_price: parseFloat(this.details.sales_qnty * result.data)
                    }
                    const objExist = this.formData.farComdetails.find(detail => detail.seed_class_id === obj.seed_class_id && detail.crop_type_id === obj.crop_type_id && detail.crop_name_id === obj.crop_name_id && detail.variety_id === obj.variety_id)
                    if (typeof objExist === 'undefined') {
                        this.formData.farComdetails.push(obj)
                    } else {
                        this.$toast.error({
                            title: 'Data has already been added',
                            color: '#D6E09B'
                        })
                    }
                } else {
                    this.$toast.error({
                        title: 'error',
                        message: result.message,
                        color: '#D6E09B'
                    })
                }
            }
            this.details = {
                seed_class_id: 0,
                crop_type_id: 0,
                crop_name_id: 0,
                variety_id: 0,
                avaliable_qnty: '',
                sales_qnty: ''
            }
            this.$refs.form.reset()
        },
        remove (key) {
            this.formData.farComdetails.splice(key, 1)
        },
        getDistrictList (divisionId) {
            const list = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && item.division_id === divisionId)
                if (divisionId) {
                    return list.filter(district => district.division_id === divisionId)
                }
                return list
        },
        getUpazilaList (districtId = null) {
            const upazillaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazillaList.filter(upazila => upazila.district_id === districtId)
            }

            return upazillaList
        },
        getCropNameList (croptypeID) {
            const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1 && item.crop_type_id === croptypeID)
            return cropNameList
        },
        getVarietyList (cropNameId) {
            const varietyList = this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.status === 1 && item.crop_name_id === cropNameId)
            return varietyList
        },
        async getVarietyAvaialableQuantity () {
            const searchIteams = {
                org_id: this.formData.org_id,
                fiscal_year_id: this.formData.fiscal_year_id,
                production_season_id: this.formData.production_season_id,
                pre_division_id: this.formData.division_id,
                pre_district_id: this.formData.district_id,
                pre_upazilla_id: this.formData.upazilla_id,
                measurement_unit_id: this.formData.measurement_unit_id,
                to_sales_office_id: this.formData.office_id,
                off_dealer_farmer_comp_id: this.formData.off_dealer_farmer_comp_id,
                seed_class_id: this.details.seed_class_id,
                crop_type_id: this.details.crop_type_id,
                crop_name_id: this.details.crop_name_id,
                variety_id: this.details.variety_id
            }
            this.loading = true
            const result = await RestApi.getData(seedFertilizerServiceBaseUrl, dealerDisburseQty, searchIteams)
            if (result.success) {
                this.details.avaliable_qnty = result.data.available_qty
                this.details.allocation_qnty = result.data.allocation_qty
                this.details.receive_qnty = result.data.sales_qty
            } else {
                this.details.avaliable_qnty = ''
                this.$toast.error({
                        title: 'error',
                        message: result.message,
                        color: '#D6E09B'
                    })
            }
            this.loading = false
        },
        getDealerList (upazillaId = null) {
        const dealerList = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.filter(dealer => dealer.per_upazilla_id === parseInt(upazillaId))
            this.dealerNo = dealerList.length
            return dealerList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        getZoneOfficeTypeList (orgId = null) {
            const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
            if (orgId) {
                return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text }
                    }
                })
            }
            return officeTypeList
        },
         getZoneOfficeList (officeTypeId) {
            const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
                if (officeTypeId) {
                    return officeList.filter(item => item.office_type_id === officeTypeId)
                }
            return officeList
        }
    }
}
</script>
